import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const ConnectWithMe = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '80px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '40px',
        }}
      >
        <Typography variant="h2">Let’s Connect Or Email Me! </Typography>
        <Button
          sx={{
            width: '420px',
          }}
          onClick={() => {
            window.location.href = 'mailto:ikoradia@umich.edu';
          }}
        >
          ikoradia@umich.edu
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <Typography variant="body1">Quick Links</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '32px',
          }}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              window.open('https://github.com/ishankoradia', '_blank')
            }
          >
            <img
              width="40px"
              height="40px"
              src="/assets/GithubIcon.svg"
              alt=""
            />
          </Box>
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://www.linkedin.com/in/ishan-koradia/',
                '_blank'
              )
            }
          >
            <img
              width="40px"
              height="40px"
              src="/assets/LinkedInIcon.svg"
              alt=""
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'fixed', // Change 'sticky' to 'fixed'
          bottom: 50, // This will stick the Box to the bottom of the viewport
          right: 50, // This will stick the Box to the right of the viewport
          cursor: 'pointer',
        }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <img width="57px" height="57px" src="/assets/UpArrow.svg" alt="" />
      </Box>
    </Box>
  );
};

export default ConnectWithMe;
