import React from 'react';
import { Box, Typography } from '@mui/material';
import { WorkEx, WorkExProps } from './Experience';

const Volunteer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '40px',
      }}
    >
      <Typography variant="h2">Volunteer work</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '80px',
          marginTop: '80px',
        }}
      >
        <WorkEx
          startEnd="Feb 2023 - Feb 2024"
          title="Student Council Lead"
          organization="Aga Khan Education Services India"
          location="Mumbai, India"
          description={
            <>
              Volunteering towards a mentorship programme for students above age
              of 18yrs called Reach for Stars. The programme spans about 9
              months wherein each student is paired with a mentor and they set
              out on a journey to accomplish the goals set out by the mentee.
              The student council acts as a bridge between the mentors and the
              mentees.
            </>
          }
        />
        <WorkEx
          startEnd="Feb 2022 - Dec 2022"
          title="Student Council Co Lead"
          organization="Aga Khan Education Services India"
          location="Mumbai, India"
          description={
            <>
              Volunteering towards a mentorship programme for students above age
              of 18yrs called Reach for Stars.
            </>
          }
        />
        <WorkEx
          startEnd="Mar 2021 - Present"
          title="Data & MIS committee"
          organization="National Council of India"
          location="Mumbai, India"
          description={<></>}
        />
      </Box>
    </Box>
  );
};

export default Volunteer;
