import { createTheme } from '@mui/material/styles';

// Create a Theme instance.
const Theme = createTheme({
  typography: {
    fontFamily: 'SF Pro Display, Playfair Display;',
    h1: {
      fontFamily: 'Playfair Display',
      fontSize: '70px',
      fontWeight: 700,
      letterSpacing: '0.3px',
      color: '#FFFFFF',
      lineHeight: 'normal',
    },
    h2: {
      fontFamily: 'Playfair Display',
      fontSize: '70px',
      fontWeight: 600,
      letterSpacing: '0.3px',
      color: '#FFFFFF',
      lineHeight: 'normal',
    },
    h4: {
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
      letterSpacing: '0.3px',
      fontFamily: 'SF Pro Display',
      color: '#FFFFFF',
    },
    h5: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      color: '#FFFFFF',
      lineHeight: 'normal',
      fontFamily: 'SF Pro Display',
    },
    body1: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0.3px',
      color: '#FFFFFF',
      lineHeight: '160%',
      fontFamily: 'SF Pro Display',
    },
    body2: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0.3px',
      color: 'rgba(255, 255, 255, 0.5)',
      lineHeight: '160%',
      fontFamily: 'SF Pro Display',
    },
  },
  palette: {
    primary: {
      main: '#FFF',
    },
    secondary: {
      main: '#F0A992',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontFamily: 'SF Pro Display',
          fontSize: '24px',
          fontStyle: 'normal',
          lineHeight: 'normal',
          textAlign: 'center',
          // fontFamily: font,
          textTransform: 'none',
          border: '1px solid #F0A992',
          padding: '16px',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#F0A992',
          ':hover': {
            cursor: 'pointer',
            background: '#F0A992',
            color: '#2F3043',
          },
        },
      },
    },
    //   MuiButtonBase: {
    //     styleOverrides: {
    //       root: {
    //         fontWeight: 500,
    //         // fontFamily: font,
    //         textTransform: 'none',
    //         border: '1px solid #FDAC62',
    //         color: '#FDAC62',
    //         ':hover': {
    //           color: 'black',
    //           background: '#FDAC62',
    //         },
    //       },
    //     },
    //   },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default Theme;
