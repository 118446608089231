import { Box, Grid, SxProps, Typography } from '@mui/material';
import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HighLightWord from './common/HighLightWord';

export interface OSSProjectProps {
  sx: SxProps;
  title: string;
  description: string | JSX.Element;
  redirectTo: string;
}

const OSSProject = ({
  sx,
  title,
  description,
  redirectTo,
}: OSSProjectProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '20px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '23px',
        ...sx,
        borderRadius: '4px',
        border: '1px solid #BECCFF',
        ':hover': {
          background: '#1E1F2F',
          boxShadow: '0px 10px 10px 0px rgba(150, 149, 149, 0.11)',
          cursor: 'pointer',
        },
      }}
      onClick={() => window.open(redirectTo, '_blank')}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '23px',
          alignSelf: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '23px',
            flex: '1 0 0',
          }}
        >
          <Typography variant="h4">{title}</Typography>
        </Box>
        <OpenInNewIcon sx={{ color: 'secondary.main' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '24px',
          alignSelf: 'stretch',
        }}
      >
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Box>
  );
};

const OSS = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '80px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '80px',
        }}
      >
        <Typography variant="h2">Open source work</Typography>
        <Typography variant="body1">
          I am very passionate about open source softwares and am always looking
          to collaborate with people from the oss community
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '80px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '100px', flexWrap: 'wrap' }}>
          <OSSProject
            sx={{ width: '40%' }}
            title="Data Structures & algorithms"
            redirectTo="https://github.com/Ishankoradia/Data-Structures-and-Algorithms"
            description="This is my playground for practising & implementing data structures. Every problem I solve here gives me a fresh perspective of looking at things."
          />
          <OSSProject
            sx={{ width: '40%' }}
            title="LLD concepts"
            redirectTo="https://github.com/Ishankoradia/LLD-concepts"
            description="Practising LLD concepts in Java here - OOPs, Encapsulation, Abstraction, Inheritance, Polymorphism, Threads, Concurrency, etc. "
          />
        </Box>
        <Box sx={{ display: 'flex', gap: '100px', flexWrap: 'wrap' }}>
          <OSSProject
            sx={{ width: '40%' }}
            title="Open LLM"
            redirectTo="https://github.com/glific/Open-LLM"
            description={
              <>
                Collaborated with other contributors to build this LLM document
                chatbot that is being integrated into{' '}
                <HighLightWord redirectTo="https://github.com/glific">
                  Glific
                </HighLightWord>
              </>
            }
          />
          <OSSProject
            sx={{ width: '40%' }}
            title="Dbt automation"
            redirectTo="https://github.com/DalgoT4D/dbt-automation"
            description={
              <>
                Contributed to this package that will automate building
                pipelines in{' '}
                <HighLightWord redirectTo="https://www.getdbt.com/product/what-is-dbt">
                  Dbt
                </HighLightWord>
                . It is being integrated and used in{' '}
                <HighLightWord redirectTo="https://github.com/DalgoT4D/DDP_backend">
                  Dalgo
                </HighLightWord>{' '}
                to help users do data trasnformation from UI (click & drag)
              </>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OSS;
