import { Box, Typography } from '@mui/material';
import React from 'react';
import HighLightWord from './common/HighLightWord';

export interface WorkExProps {
  startEnd: string;
  title: string;
  organization: string;
  location: string;
  description: JSX.Element | string;
}

export const WorkEx = ({
  startEnd,
  title,
  organization,
  location,
  description,
}: WorkExProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '70px',
        width: '100%',
      }}
    >
      <Box sx={{ width: '20%' }}>
        <Typography variant="body1" fontSize="20px">
          {startEnd}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '32px',
          flexDirection: 'column',
          width: '80%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: '23px',
          }}
        >
          <Typography variant="h4">{title}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '24px',
            }}
          >
            <Typography variant="body2">{organization}</Typography>
            <img width="6px" height="6px" src="/assets/Dot.svg" alt="" />
            <Typography variant="body2">{location}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body1">{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Experience = () => {
  return (
    <Box
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '80px',
      }}
    >
      <Typography variant="h2">Experience</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '80px',
          marginTop: '80px',
        }}
      >
        <WorkEx
          startEnd="Jan 2023 - Present"
          title="Software Engineer - Dalgo"
          organization="Think201"
          location="Banglore, India"
          description={
            <>
              <HighLightWord redirectTo="https://think201.com/">
                Think201
              </HighLightWord>{' '}
              is a tech partner to{' '}
              <HighLightWord redirectTo="https://projecttech4dev.org/">
                Project Tech4dev
              </HighLightWord>{' '}
              that builds open source low cost products for social sector. One
              such product that I have been working with them is{' '}
              <HighLightWord redirectTo="https://github.com/DalgoT4D">
                Dalgo
              </HighLightWord>
              . Dalgo is an open-source data platform that enables NGOs to build
              and manage their data pipelines.
            </>
          }
        />
        <WorkEx
          startEnd="Jan 2022 - Jan 2023"
          title="Software Engineer"
          organization="Think201"
          location="Banglore, India"
          description={
            <>
              Built api backends for various platforms on NodeJS. Learned how to
              use redis for caching. Applied python skills to build an order
              (options trading) analyzer.{' '}
            </>
          }
        />
      </Box>
    </Box>
  );
};

export default Experience;
