import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';

const Header = ({ sx }: { sx: SxProps }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
        padding: '34px 0px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
          flex: '1 0 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <img
            width="40px"
            height="40px"
            src="/assets/ishanILetter.svg"
            alt=""
          />
          <Typography variant="h5">Ishan</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          gap: '12px',
          alignSelf: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '10px',
            gap: '40px',
            ':hover': {
              cursor: 'pointer',
              borderBottom: '1px solid #FFF',
              paddingBottom: '3px',
            },
          }}
          onClick={() =>
            window.open('https://github.com/ishankoradia', '_blank')
          }
        >
          <Typography variant="h5" sx={{}}>
            Github
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '10px',
            gap: '40px',
            ':hover': {
              cursor: 'pointer',
              borderBottom: '1px solid #FFF',
              paddingBottom: '3px',
            },
          }}
          onClick={() =>
            window.open('https://www.linkedin.com/in/ishan-koradia/', '_blank')
          }
        >
          <Typography variant="h5" sx={{}}>
            LinkedIn
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '10px',
            gap: '40px',
            ':hover': {
              cursor: 'pointer',
              borderBottom: '1px solid #FFF',
              paddingBottom: '3px',
            },
          }}
          onClick={() =>
            window.open(
              'https://drive.google.com/file/d/1lt9rfgclQLh00jEeRFAaUUFALnGc72iL/view?usp=sharing',
              '_blank'
            )
          }
        >
          <Typography variant="h5" sx={{}}>
            CV
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
