import React from 'react';
import SectionLayout from './Layout/SectionLayout';
import HomeLayout from './Layout/HomeLayout';
import Header from './components/Header';
import AboutSection from './components/AboutSection';
import Experience from './components/Experience';
import OSS from './components/OSS';
import Volunteer from './components/Volunteer';
import ConnectWithMe from './components/ConnectWithMe';
import { Divider } from '@mui/material';
import Footer from './components/Footer';

const Home = () => {
  return (
    <HomeLayout>
      <SectionLayout>
        <Header sx={{}} />
      </SectionLayout>
      <Divider orientation="horizontal" sx={{ border: '1px solid #646569' }} />
      <SectionLayout sx={{ marginTop: '42px' }}>
        <AboutSection />
      </SectionLayout>
      <SectionLayout sx={{ marginTop: '140px' }}>
        <Experience />
      </SectionLayout>
      <SectionLayout sx={{ marginTop: '140px' }}>
        <Volunteer />
      </SectionLayout>
      <SectionLayout sx={{ marginTop: '140px' }}>
        <OSS />
      </SectionLayout>
      <SectionLayout sx={{ marginTop: '140px' }}>
        <ConnectWithMe />
      </SectionLayout>
      <SectionLayout sx={{ marginTop: '40px' }}>
        <Divider
          orientation="horizontal"
          sx={{ border: '1px solid #646569' }}
        />
      </SectionLayout>
      <SectionLayout sx={{ margin: '0px 40px' }}>
        <Footer />
      </SectionLayout>
    </HomeLayout>
  );
};

export default Home;
