import { Box, SxProps } from '@mui/material';
import React from 'react';

interface SectionLayoutProps {
  sx: SxProps;
  children: any;
}

const SectionLayout = ({ sx, children }: SectionLayoutProps) => {
  return <Box sx={{ padding: '0px 140px', ...sx }}>{children} </Box>;
};

export default SectionLayout;
