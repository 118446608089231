import React from 'react';
import { Box, Typography } from '@mui/material';
import CopyrightIcon from '@mui/icons-material/Copyright';

const Footer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        padding: '40px',
      }}
    >
      <CopyrightIcon sx={{ color: 'white' }} />
      <Typography variant="body1" fontSize="20px">
        Ishan Koradia 2024. All Rights Reserved
      </Typography>
    </Box>
  );
};

export default Footer;
