import { Box, SxProps } from '@mui/material';
import React from 'react';

interface HomeLayoutProps {
  children: any;
}

const SectionLayout = ({ children }: HomeLayoutProps) => {
  return <Box sx={{ backgroundColor: '#2F3043' }}>{children} </Box>;
};

export default SectionLayout;
