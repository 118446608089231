import React from 'react';
import { Box, Typography } from '@mui/material';
import HighLightWord from './common/HighLightWord';

const AboutSection = () => {
  return (
    <Box
      sx={{
        padding: '80px 0px',
        display: 'flex',
        gap: '69px',
      }}
    >
      <Box sx={{ justifyContent: 'flex-end' }}>
        <img
          width="265px"
          height="265px"
          src="/assets/ishan-profile.png"
          alt=""
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '40px',
        }}
      >
        <Typography variant="h1">
          Hi, I’m <br /> Ishan Koradia.
        </Typography>

        <Typography variant="h4">Software Engineer (Backend)</Typography>

        <Typography variant="body1">
          I like helping others and solving problems more than an average
          person. My goal has always been to use my software skills to improve
          quality of life of people around me.
        </Typography>
        <Typography variant="body1">
          Coming from an Electrical Engineering background, I have been honing
          my software skills for little over 2 years now. I have worked on
          various tech stacks ranging from javascript / PhP / Laravel to NodeJS
          to Python / Django / NextJS. Java is my next stop, I am curious to
          learn the inner workings of Java & draw comparisons with Python. I
          believe languages don't matter as long as you get the fundamentals
          right.
        </Typography>

        <Typography variant="body1">
          Currently I am working on a very cool{' '}
          <HighLightWord redirectTo="https://dalgo.in/">
            open source platform
          </HighLightWord>{' '}
          designed for the NGOs & the social sector to build their data
          pipelines.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutSection;
