import React from 'react';
import { Box } from '@mui/material';

const HighLightWord = ({
  children,
  redirectTo,
}: {
  children: any;
  redirectTo: string;
}) => {
  return (
    <Box
      component="span"
      sx={{
        color: 'secondary.main',
        ':hover': {
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      }}
      onClick={() => window.open(redirectTo, '_blank')}
    >
      {children}
    </Box>
  );
};

export default HighLightWord;
